var render, staticRenderFns
import script from "./carousel.vue?vue&type=script&lang=js&"
export * from "./carousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51efd1ca')) {
      api.createRecord('51efd1ca', component.options)
    } else {
      api.reload('51efd1ca', component.options)
    }
    
  }
}
component.options.__file = "tmp/tmp-8TvCdymjUCkQN/JS/carousel.vue"
export default component.exports